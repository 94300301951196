import React, { useState } from 'react';

import Layout from '@layouts/Layout';

import TableOfContents from '@components/_ui/TableOfContents';
import DownloadCard from '@components/_ui/DownloadCard';
import Icon from '@components/_ui/Icon';

const StandAloneAssembly = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleIsOpen = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    return (
        <Layout metaData={{ title: 'Arbour Assembly - Stand-alone assembly' }}>
            <header className="hero">
                <div className="container">
                    <h1 className="text-page-title">Stand-alone assembly</h1>
                </div>
            </header>
            <section className="island--xlarge container">
                <div className="bracket">
                    <TableOfContents isOpen={isOpen} />
                    <div className="bracket__flex has-toc flow--large">
                        <div className="toc-controls">
                            <div className="breathe">
                                <button type="button" className="toc-controls__button button--white button--reverse">
                                    <Icon id="chevron-left" className="button__icon icon--small" />
                                    <span>Previous</span>
                                </button>
                                <button type="button" className="toc-controls__button button--white">
                                    <span>Next</span>
                                    <Icon id="chevron-right" className="button__icon icon--small" />
                                </button>
                            </div>
                            <a href="#toc-open" title="Open table of contents" className="toc-controls__button toc-controls__toggle button--white" onClick={handleToggleIsOpen}>
                                <span>Contents</span>
                            </a>
                        </div>
                        <div className="wysiwyg">
                            <h2>Introduction</h2>
                            <p>There are 3 different sizes of 'stand-alone' pergola modules and 2 sizes of 'arbours' to choose from when ordering your preferred option. The pergolas are based upon our standard building blocks of approximately 9ft x 9ft whilst the arbours come in 2 different 'walk-through' lengths to give you a tunnel like structure linking one part of the garden to another.</p>
                            <p>The standard 'building block' system is designed to be extendable in a simple and easy way, proving that you do not need to be a construction engineer to install one!</p>
                            <p>The following sizes are available:</p>
                        </div>

                        <div className="flow--medium">
                            <h2 className="text-subtitle">Downloads &amp; Support</h2>
                            <div>
                                <div className="grid-x grid-margin-x grid-margin-y">
                                    <div className="cell medium-6">
                                        <DownloadCard />
                                    </div>
                                    <div className="cell medium-6">
                                        <DownloadCard />
                                    </div>
                                    <div className="cell medium-6">
                                        <DownloadCard />
                                    </div>
                                    <div className="cell medium-6">
                                        <DownloadCard />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default StandAloneAssembly;
